<template>
	<main class="page-container container">
		<div class="page-index">
			<h3 class="text-left page_index__title">{{ displayLang.title }}</h3>
			<div class="d-flex flex-column page_index__text">
				<p class="page_index__text__item">
					{{ displayLang.text1 }}
				</p>
				<p class="page_index__text__item">
					{{ displayLang.text2 }}
				</p>
			</div>
			<div class="page__index__videoplacement">
				<iframe src="https://www.youtube.com/embed/m6ls-wg9sqg" frameborder="0" allowfullscreen></iframe>
				<iframe src="https://www.youtube.com/embed/KJJc785jRdw" frameborder="0" allowfullscreen></iframe>
				<iframe src="https://www.youtube.com/embed/0FWWHgDayv0" frameborder="0" allowfullscreen></iframe>
				<iframe src="https://www.youtube.com/embed/TySZ2LaJZvU" frameborder="0" allowfullscreen></iframe>
			</div>
			<div class="page_index__text">
				<h3 class="page_index__text__item">{{ displayLang.contitle1 }}</h3>
				<ul>
					<li v-for="(item, idx) in displayLang.context1" :key="idx">{{ item }}</li>
				</ul>
			</div>
			<div class="page_index__text">
				<h3 class="page_index__text__item">{{ displayLang.contitle2 }}</h3>
				<ul>
					<li v-for="(item, idx) in displayLang.context2" :key="idx">{{ item }}</li>
				</ul>
			</div>
		</div>
	</main>
</template>
<script>
import LanguageService from "../../services/LanguageService";
export default {
	name: "about",
	data() {
		return {
			currentLang: LanguageService.getLanguage(),

			uz: {
				title: "Portal haqida",
				text1: "Portal  O‘zbekiston Respublikasi Prezidentining “Sanoat kooperatsiyasini yanada rivojlantirish va talabi yuqori mahsulotlar ishlab chiqarishni kengaytirish chora-tadbirlari toʻgʻrisida”gi 2019 yil 1-maydagi PQ-4302-sonli Qarori va Oʻzbekiston Respublikasi Vazirlar Mahkamasining “Sanoat yarmarkalari va elektron kooperatsiya birjasi faoliyatini tashkil etish chora-tadbirlari toʻgʻrisida”gi 2019 yil 30 sentyabrdagi 833-son qaroriga muvofiq ishlab chiqilgan.",
				text2: "Elektron kooperatsiya portali - bu mahalliy ishlab chiqaruvchilar oʻrtasida sanoat sohasidagi kooperatsiya aloqalarini mustahkamlash va rivojlantirish, sanoat mahsulotlari ishlab chiqarishni kengaytirish va kichik biznes subyektlarini sanoat faoliyatiga keng jalb qilish maqsadida yaratilgan maxsus savdo maydonchasidir.",
				contitle1: "Portalning asosiy vazifalari quyidagilardan iborat:",
				contitle2: "Portal quyidagi funksiyalarni bajaradi:",
				context1: [
					"Elektron kooperatsiya portali (keyingi oʻrinlarda Portal) orqali mahalliy sanoat korxonalari tomonidan ishlab chiqarilgan tayyor mahsulotlar, butlovchi buyumlar, xom ashyo va materiallarni sotish va sotib olish imkoniyatini berish;",
					"Xoʼjalik yurituvchi subyektlar, shuningdek mijozlar va yetkazib beruvchilar oʻrtasida shartnomalar tuzish va toʻlovlarni amalga oshirishda munosabatlar erkinligini va oshkoraligini taʼminlash mexanizmini joriy etish;",
					"respublikada ishlab chiqarilgan sanoat mahsulotlarining turlari, texnik xususiyatlari va sifat koʻrsatkichlari toʻgʻrisida barcha manfaatdor doiralarni xabardor qilish;",
					"Portal orqali amalga oshirilgan xaridlar boʻyicha davlat xaridlari boʻyicha maxsus portal orqali maʼlumot almashishni taʼminlash;",
					"portalda tuzilgan shartnomalarning bajarilishini tahlil qilish, shuningdek mahalliy xom ashyo asosida tayyor mahsulot ishlab chiqarishni rivojlantirishda sanoat kooperatsiyasining roli va ahamiyatini oshirish boʻyicha takliflar tayyorlash.",
				],
				context2: [
					"vazirlikka yuklatilgan operator funktsiyalarini bajaradi, Portal umumiy faoliyatini muvofiqlashtirish va nazorat qilish;",
					"statistika, bojxona, sertifikatlashtirish, soliq, moliya va boshqa idoralarning axborot tizimlari va maʼlumotlar bazalari bilan hamkorlikdagi yaxlit faoliyatni amalga oshirish;",
					"portalda import va eksportga moʻljallangan mahsulotlar turlari va hajmlari toʻgʻrisida maʻlumotlar eʼlon qilish, shuningdek iqtisodiyotdagi yuqori talabli va ishlab chiqarishga tavsiya etiladigan import oʻrnini bosuvchi mahsulotlar roʻyxatini joylashtirib borish;",
					"erkin raqobat va xaridorlar hamda ishlab chiqaruvchilar oʻrtasidagi narxlarning shaffofligi tamoyillari asosida elektron savdoni tashkil qilish va elektron shartnomalarni imzolash mexanizmini taʼminlash;",
					"portalda roʻyxatdan oʻtgan faol korxonalarning reytingini yuritish va kim oshdi savdosida tuzilgan shartnomalarning bajarilish holatini nazorat qilish;",
					"respublikada ishlab chiqarilgan sanoat mahsulotlari toʻgʻrisida toʻgʻri va aniq maʼlumotlarning joylashtirilishini, ushbu maʼlumotlarning oʻz vaqtida yangilanishini taʼminlash va nazorat qilish;",
					"Portal orqali tuzilgan shartnomalarni tahlil qilish va tegishli tadbirlarni amalga oshirish;",
					"mahalliy xom ashyo asosida tayyor mahsulot ishlab chiqarishni rivojlantirishda Portalning roli va ahamiyatini oshirish boʻyicha takliflarni ishlab chiqish hamda amalga tadbiq etish;",
					"zamonaviy talablar asosida Portal faoliyatini takomillashtirish va uning imkoniyatlarini kengaytirish;",
					"ishlab chiqarishni mahalliylashtirish va sanoatdagi kooperatsiyani kengaytirish maqsadida oʻtkaziladigan mintaqaviy sanoat yarmarkalarida hamda kooperatsiya birjasi tadbirlarida ishtirok etish.",
				],
			},

			ru: {
				title: "О портале",
				text1: "Портал разработан в соответсвии с Постановление Президента Республики Узбекистан «О мерах по дальнейшему развитию промышленной кооперации и расширению производства востребованной продукции» №ПП-4302 от 01.05.2019 года и Постановление Кабинета Министров Республики Узбекистан «О мерах по организации деятельности промышленной ярмарки и электронной кооперационной биржи» №833 от 30.09.2019 года.",
				text2: "Электронный кооперационный портал – это специальная торговая площадка, созданная с целью укрепления и развития кооперационных связей в промышленности между отечественными производителями, расширения производства промышленной продукции и широкого вовлечения субъектов малого бизнеса в промышленную деятельность.",
				contitle1: "Основными задачами Портала являются:",
				contitle2: "Портал выполняет следующие функции:",
				context1: [
					"предоставление возможности реализации и приобретения готовой продукции, комплектующих изделий, сырья и материалов, производимых отечественными промышленными предприятиями через Электронный кооперационный портал (далее Портал);",
					"внедрение механизма обеспечения свободы отношений и прозрачности заключения договоров и платежей между хозяйствующими субъектами, а также заказчиками и поставщиками;",
					"информирование всех заинтересованных кругов о видах, технических характеристиках и параметрах качества производимой в республике промышленной продукции;",
					"обеспечение обмена информацией между специальным информационным порталом по государственным закупкам о совершенных закупках через Портал;",
					"анализ выполнения заключенных договоров в портале, а также подготовка предложений по повышению роли и значения промышленной кооперации в освоении производства готовых продукций на базе местного сырья.",
				],
				context2: [
					"выполняет операторские функции, возложенные на министерство, координирует и контролирует общую деятельность Портала;",
					"ведет интегрированную деятельность с информационными системами и базами данных органами статистики, таможни, сертификации, налоговой, финансов и других;",
					"публикация на Портале о видах и объемах импортной и экспортноориентированной продукции, а также размещения перечня востребованных в экономике и рекомендованных к освоению импортозамещающих продукций;",
					"организация электронных торгов, основанных на принципах свободной конкуренции и прозрачности формирования цен между заказчиками и производителями и предоставление механизма подписания электронных договоров;",
					"ведение рейтинга зарегистрированных в Портале активных предприятий и состояния исполнения договоров заключенных в торгах;",
					"обеспечение размещения правильной и реальной информации о промышленной продукции, производимой в республике своевременного обновления данной информации и контроля за ним;",
					"ведет анализ заключенных договоров посредством Портала и осуществляет соотвествующие мероприятия;",
					"разрабатывает и реализует предложения по повышению роли и значения Портала в освоении производства готовой продукции на базе местного сырья;",
					"совершенствует деятельность Портала на основе современных требований и расширяет его функциональные возможности;",
					",принимает участие на региональных промышленных ярмарках и мероприятиях кооперационной биржы, проводимых с целью локализации производства и расширения кооперационной связи в промышленности.",
				],
			},
			cyrl: {
				title: "Портал ҳақида",
				text1: "Портал Ўзбекистон Республикаси Президентининг “Саноат кооперациясини янада ривожлантириш ва талаби юқори маҳсулотлар ишлаб чиқаришни кенгайтириш чора-тадбирлари тўғрисида”ги 2019 йил 1 майдаги ПҚ-4302-сонли Қарори ва Ўзбекистон Республикаси Вазирлар Маҳкамасининг “Саноат ярмаркалари ва электрон кооперация биржаси фаолиятини ташкил этиш чора-тадбирлари тўғрисида”ги 2019 йил 30 сентябрдаги 833-сон қарорига мувофиқ ишлаб чиқилган.",
				text2: "Электрон кооперация портали - бу маҳаллий ишлаб чиқарувчилар ўртасида саноат соҳасидаги кооперация алоқаларини мустаҳкамлаш ва ривожлантириш, саноат маҳсулотлари ишлаб чиқаришни кенгайтириш ва кичик бизнес субъектларини саноат фаолиятига кенг жалб қилиш мақсадида яратилган махсус савдо майдончасидир.",
				contitle1: "Порталнинг асосий вазифалари қуйидагилардан иборат:",
				contitle2: "Портал қуйидаги функцияларни бажаради:",
				context1: [
					"Электрон кооперация портали (кейинги ўринларда Портал) орқали маҳаллий саноат корхоналари томонидан ишлаб чиқарилган тайёр маҳсулотлар, бутловчи буюмлар, хом ашё ва материалларни сотиш ва сотиб олиш имкониятини бериш;",
					"Хўжалик юритувчи субъектлар, шунингдек мижозлар ва етказиб берувчилар ўртасида шартномалар тузиш ва тўловларни амалга оширишда муносабатлар эркинлигини ва ошкоралигини таъминлаш механизмини жорий этиш;",
					"республикада ишлаб чиқарилган саноат маҳсулотларининг турлари, техник хусусиятлари ва сифат кўрсаткичлари тўғрисида барча манфаатдор доираларни хабардор қилиш;",
					"Портал орқали амалга оширилган харидлар бўйича давлат харидлари бўйича махсус портал орқали маълумот алмашишни таъминлаш;",
					"порталда тузилган шартномаларнинг бажарилишини таҳлил қилиш, шунингдек маҳаллий хом ашё асосида тайёр маҳсулот ишлаб чиқаришни ривожлантиришда саноат кооперациясининг роли ва аҳамиятини ошириш бўйича таклифлар тайёрлаш.",
				],
				context2: [
					"вазирликка юклатилган оператор функцияларини бажаради, Портал умумий фаолиятини мувофиқлаштириш ва назорат қилиш;",
					"статистика, божхона, сертификатлаштириш, солиқ, молия ва бошқа идораларнинг ахборот тизимлари ва маълумотлар базалари билан ҳамкорликдаги яхлит фаолиятни амалга ошириш;",
					"порталда импорт ва экспортга мўлжалланган маҳсулотлар турлари ва ҳажмлари тўғрисида маълумотлар эълон қилиш, шунингдек иқтисодиётдаги юқори талабли ва ишлаб чиқаришга тавсия этиладиган импорт ўрнини босувчи маҳсулотлар рўйхатини жойлаштириб бориш;",
					"эркин рақобат ва харидорлар ҳамда ишлаб чиқарувчилар ўртасидаги нархларнинг шаффофлиги тамойиллари асосида электрон савдони ташкил қилиш ва электрон шартномаларни имзолаш механизмини таъминлаш;",
					"порталда рўйхатдан ўтган фаол корхоналарнинг рейтингини юритиш ва ким ошди савдосида тузилган шартномаларнинг бажарилиш ҳолатини назорат қилиш;",
					"республикада ишлаб чиқарилган саноат маҳсулотлари тўғрисида тўғри ва аниқ маълумотларнинг жойлаштирилишини, ушбу маълумотларнинг ўз вақтида янгиланишини таъминлаш ва назорат қилиш;",
					"Портал орқали тузилган шартномаларни таҳлил қилиш ва тегишли тадбирларни амалга ошириш;",
					"маҳаллий хом ашё асосида тайёр маҳсулот ишлаб чиқаришни ривожлантиришда Порталнинг роли ва аҳамиятини ошириш бўйича таклифларни ишлаб чиқиш ҳамда амалга тадбиқ этиш;",
					"замонавий талаблар асосида Портал фаолиятини такомиллаштириш ва унинг имкониятларини кенгайтириш;",
					"ишлаб чиқаришни маҳаллийлаштириш ва саноатдаги кооперацияни кенгайтириш мақсадида ўтказиладиган минтақавий саноат ярмаркаларида ҳамда кооперация биржаси тадбирларида иштирок этиш.",
				],
			},
		};
	},
	computed: {
		displayLang() {
			switch (this.currentLang) {
				case "ru":
					return this.ru;
				case "cyrl":
					return this.cyrl;
				default:
					return this.uz;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.page__index__videoplacement {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	iframe {
		width: 100%;
		height: 320px;
		border-radius: 10px;
	}
}
.page-container {
	padding-bottom: 20px;
}
.page-index {
	background: white;
	border-radius: 8px;
	box-shadow: 0 1px 10px rgb(0 0 0 / 0.1);
	padding: 40px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.page_index__title {
	font-size: 28px;
}
.page_index__text {
	gap: 12px;

	display: flex;
	flex-direction: column;
}
.page_index__text__item {
	font-weight: bold;
	font-size: 18px;
	text-align: justify;
}
.page_index__text ul {
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding-left: 10px;
	padding-left: 10px;
	list-style-type: circle;
}
.page_index__text ul li {
	list-style-type: disc;
	text-align: justify;
}
</style>

